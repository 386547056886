<template>
  <div class="livePlayer" id="livePlayer" :style="`width:${w};height:${h}`">

    <!-- <div class="tisp">直播即将上线，敬请期待</div> -->
    <!-- <div
      id="player"
      :style="`width:${w}${fullscreen ? '!important' : ''};height:height:${
        fullscreen ? '100%' : h
      }`"
    ></div> -->
    <video ref="videoPlayer" id="videoPlayer" style="width: 100%;height:100%" muted autoplay controls
      src="https://static.ppzx168.com.cn/zb/yjgzb.mp4"></video>

    <!-- <div class="video-backgroundW" :style="`width:${w}${fullscreen ? '!important' : ''};height:${fullscreen ? '100%' : h
      }`">
      <canvas v-show="!imgSrc" id="video-background" :style="`width:${w}${fullscreen ? '!important' : ''};height:${fullscreen ? '100%' : h
        }`"></canvas>
      <img v-show="imgSrc" id="video-background" :style="`width:${w}${fullscreen ? '!important' : ''};height:${fullscreen ? '100%' : h
        }`" :src="imgSrc" alt="" />
    </div> -->
    <!-- <canvas id="myCanvas" :style="`width:${w}${fullscreen ? '!important' : ''};height:${fullscreen ? '100%' : h
      };object-fit: contain !important; opacity:0`"></canvas>
    <div class="myCanvasW" :style="`width:${w}${fullscreen ? '!important' : ''}`" v-show="imgSrc">
      <img id="myCanvas" :src="imgSrc" alt="" style="object-fit: contain !important" />
    </div> -->

    <div class="liveCartGoods" v-if="shopData.is_live">
      <div class="goodsNum">
        {{ shopData.shop_live_log_info.goods_uuids.length }}件商品
      </div>
      <el-popover class="" placement="bottom" trigger="click">
        <div class="cartGoods" slot="reference">
          <img src="../../assets/liveCarticon.png" alt="" class="liveCarticon" />
          <span>{{ liveGoodsList[0].name }}</span>
        </div>
        <div class="loadDowntips">
          <img src="../../assets/qr.png" style="width: 100%;height:100%;" alt="扫码下载" />
          <!-- <div class="text">扫码打开购物袋下单</div> -->
        </div>
      </el-popover>
    </div>

    <div class="livePlayerHeader" :style="`width:${w}${fullscreen ? '!important' : ''}`">
      <div class="livePlayerHeaderLeft">
        <div class="shopInfo">
          <img class="shopLogo" :src="shopData.thum" alt="" />
          <div class="shopName">{{ shopData.name }}</div>
        </div>
        <div class="liveLookNum">
          <img src="../../assets/watchNumIcon.png" alt="" />
          <div class="num">{{ memberCount }}</div>
        </div>
      </div>

      <el-popover class="" placement="bottom" trigger="click">
        <div class="livePlayerHeaderRight" slot="reference">
          +&nbsp;&nbsp;关注
        </div>
        <div class="loadDowntips">
          <img src="../../assets/qr.png" style="width: 100%;height:100%;" alt="扫码下载" />
          <!-- <div class="text">扫码下载APP关注</div> -->
        </div>
      </el-popover>
    </div>

    <div class="controls" :style="`width:${w}${fullscreen ? '!important' : ''}`">
      <!-- <div class="progress-bar" >
        <div class="progress" :style="{ width: progressWidth }"></div>
      </div> -->
      <div class="controlsLeft">
        <!-- <img v-show="!imgSrc" class="livezanting" @click="setLive('paused')" src="../../assets/livezanting.png" alt="" />
        <img v-show="imgSrc" class="livezanting" @click="setLive('play')" src="../../assets/livebofang.png" alt="" />
        <img @click="videoRefresh" class="liveshuaxin" src="../../assets/liveshuaxin.png" alt="" /> -->
      </div>
      <div class="controlsRight">
        <el-popover popper-class="zhuanfapopover" style="background: transparent" placement="bottom" trigger="click">
          <img slot="reference" class="liveZhuanfaIcon" style="width: 0.98958vw; height: 0.98958vw; display: flex"
            src="../../assets/liveZhuanfaIcon.png" alt="" />
            <div class="loadDowntips">
            <img src="../../assets/qr.png" style="width: 100%;height:100%;" alt="扫码下载" />
            <!-- <div class="text">下载APP进行举报</div> -->
          </div>
          <!-- <div class="zhuanfaloadDowntips">
            <img class="shopLogo" :src="shopData.thum" alt="" />
            <div class="searchText">
              请搜索“<span>{{ shopData.name }}</span>”
            </div>
            <div class="playing">正在直播中</div>
            <div class="zhuanfaQr">
              <img src="../../assets/qr.png" alt="" />
            </div>
            <div class="text">扫一扫 下载APP观看</div>
          </div> -->
        </el-popover>
        <img v-show="muted != 0" @click="shengyin(0)" class="shengyinIcon" style="width: 0.98958vw; height: 0.78125vw"
          src="../../assets/shengyinIcon.png" alt="" />
        <img v-show="muted == 0" @click="shengyin(50)" class="shengyinIcon" style="width: 0.98958vw; height: 0.78125vw"
          src="../../assets/livejingyin.png" alt="" />
        <el-popover class="" placement="bottom" trigger="click">
          <img slot="reference" class="jvbaoIcon" style="width: 0.98958vw; height: 0.98958vw; display: flex"
            src="../../assets/jvbaoIcon.png" alt="" />
          <div class="loadDowntips">
            <img src="../../assets/qr.png" style="width: 100%;height:100%;" alt="扫码下载" />
            <!-- <div class="text">下载APP进行举报</div> -->
          </div>
        </el-popover>

        <img class="qingpingIcon" style="width: 0.67708vw; height: 0.9375vw" src="../../assets/qingpingIcon.png" alt="" />
        <img v-show="!fullscreen" @click="FullScreen" class="quanpingIcon" style="width: 0.83333vw; height: 0.83333vw"
          src="../../assets/quanpingIcon.png" alt="" />
        <img v-show="fullscreen" @click="ExitFullscreen" class="quanpingIcon" style="width: 0.83333vw; height: 0.83333vw"
          src="../../assets/quanpingIcon-2.png" alt="" />
      </div>
    </div>
    <div class="commentListW" v-show="!fullscreen">
      <div class="commentList">
        <div class="commentitem" v-for="(item, index) in commentList" :key="index">
          <span class="commentitemName">{{ item.name }}：</span>
          <span class="commentitemMsg">
            {{ item.msg }}
          </span>
        </div>
      </div>
      <div class="commentBottomBox">
        <div class="commentIptBox">
          <img class="bianjiIcon" src="../../assets/bianji.png" alt="" />
          <input placeholder="与主播互动一下…" type="text" />
        </div>
        <div class="sendMsg">
          <el-popover class="" placement="bottom" trigger="click">
            <img slot="reference" class="sendMsgIcon" src="../../assets/sendMsg.png" alt="" />

            <div class="loadDowntips">
              <img src="../../assets/qr.png" style="width: 100%;height:100%;" alt="扫码下载" />
              <!-- <div class="text">下载APP与主播互动</div> -->
            </div>
          </el-popover>
        </div>
      </div>
    </div>

    <!-- 关闭按钮 -->
    <div class="to-back" @click="$router.back(-1)">
      <i class="van-icon van-icon-cross"></i>
    </div>
  </div>
</template>

<script>
// import AgoraRTC from "agora-rtc-sdk-ng";
// import AgoraRTM from "agora-rtm-sdk";

// import { shopsRead, liveGoods } from "../../utils/api.js";

// const AgoraClient = AgoraRTC.createClient({
//   mode: "live", // 模式
//   codec: "vp8", // 编码格式
//   role: "audience", // 角色
// });
// const client = AgoraRTM.createInstance("84ff6fab3c894b70ba847e2bcd46b3fe");

// var remoteAudioTrack = null;
// var remoteVideoTrack = null;
export default {
  data() {
    return {
      options: {
        // 替换成你自己项目的 App ID。
        appId: "84ff6fab3c894b70ba847e2bcd46b3fe",
        // 传入目标频道名。
        channel: "",
        // 如果你的项目开启了 App 证书进行 Token 鉴权，这里填写生成的 Token 值。
        token: null,
        // 设置频道内的用户角色，可设为 "audience" 或 "host"
        role: "audience",
        uid:
          ("1" + `${+new Date()}`.substring(`${+new Date()}`.length - 8)) * 1,
      },
      userUid: "",
      uuid: "",
      w: "100%",
      h: "100%",
      commentList: [],
      shopData: "",
      fullscreen: false,
      liveGoodsList: [],
      memberCount: '500+',
      timer: null,
      memberCountTime: null,
      channelClient: null,
      imgSrc: "",
      muted: 0,
      progressWidth:'0%'
    };
  },

  created() {
    if (this.$route.query.uuid) {
      this.h = window.innerHeight - 97 + "px";
      this.uuid = this.$route.query.uuid;
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.videoPlayer.muted = false;
      this.$refs.videoPlayer.play();


      var myVideo = document.getElementById('videoPlayer');
      if (myVideo.paused) {
        console.log('暂停');
        // myVideo.play();
        // console.log(321);
        // this.videoPlay();
        this.imgSrc = "1";

      } else {
        console.log('播放');
        this.imgSrc = "";

        // myVideo.pause();
      }

      const video = this.$refs.videoPlayer
    // 监听视频时间更新事件
    video.addEventListener('timeupdate', this.updateProgress)
    })

    window.addEventListener("fullscreenchange", (e) => {
      // 监听到屏幕变化，在回调中判断是否已退出全屏
      console.log("监听全屏变化：", e);
      if (!that.checkFull()) {
        console.log("监听不是全屏变化：");
        that.ifFullscreen = false;
        this.fullscreen = false;
      } else {
        console.log("监听全屏变化：");
        this.fullscreen = true;
      }
    });
    // client.login({
    //   uid: "1" + `${+new Date()}`.substring(`${+new Date()}`.length - 8),
    // });
    this.scrollToBottom();
    // this.getShopsData();
    const that = this;

    window.onresize = () => {
      return (() => {
        that.h = window.innerHeight - 97 + "px";
      })();
    };
  },

  beforeDestroy() {
    // let user = this.userUid;
    // AgoraClient.unsubscribe(user);
    // AgoraClient.leave();
    // this.channelClient.leave(this.options.channel);
    // client.logout();
    // clearInterval(this.memberCountTime);
    // console.log(this.memberCountTime);
  },

  watch: {
    h(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.h);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    // 监听视频时间更新事件，更新进度条宽度
    updateProgress() {
      const video = this.$refs.videoPlayer
      const currentTime = video.currentTime
      const duration = video.duration
      this.progressWidth = `${(currentTime / duration) * 100}%`
    },

    videoRefresh() {
      // let user = this.userUid;
      // AgoraClient.unsubscribe(user);
      // AgoraClient.leave();
      // this.channelClient.leave(this.options.channel);
      // clearInterval(this.memberCountTime);
      // this.startBasicLive();
    },
    shengyin(num) {
      // remoteAudioTrack.setVolume(num);

      this.muted = num;
      if (num) {
        this.$refs.videoPlayer.muted = false;
      } else {
        this.$refs.videoPlayer.muted = true;
      }

    },
    setLive(type) {
      var myVideo = document.getElementById(`videoPlayer`);

      if (type == "paused") {
        //截取当前帧的图片
        // var v = document.querySelector("video");
        // let canvas = document.getElementById("myCanvas");
        // var ctx = canvas.getContext("2d");
        // var cw = Math.floor(v.clientWidth);
        // var ch = Math.floor(v.clientHeight);
        // canvas.width = cw;
        // canvas.height = ch;
        // ctx.drawImage(v, 0, 0, cw, ch);
        // var oGrayImg = canvas.toDataURL("image/jpeg");
        // console.log(oGrayImg);
        // this.imgSrc = oGrayImg;

        // console.log(remoteAudioTrack);
        // remoteAudioTrack.setVolume(0);

        this.imgSrc = "1";
        myVideo.pause();

      } else {
        this.imgSrc = "";
        myVideo.play();


        // remoteAudioTrack.setVolume(100);
      }
    },

    checkFull() {
      let isFull =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement;
      console.log("全屏吗：", isFull);
      if (isFull === undefined || isFull === null) isFull = false;
      return isFull;
    },
    //进入全屏
    FullScreen() {
      var ele = document.getElementById("livePlayer");
      if (ele.requestFullscreen) {
        ele.requestFullscreen();
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
      } else if (ele.webkitRequestFullScreen) {
        ele.webkitRequestFullScreen();
      }
      this.fullscreen = true;
    },

    //退出全屏
    ExitFullscreen() {
      var de = document;
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen();
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen();
      }
      this.fullscreen = false;
    },

    scrollToBottom() {
      this.$nextTick(() => {
        var container = this.$el.querySelector(".commentList");
        console.log(container);
        container.scrollTop = container.scrollHeight;
      });
    },
    getShopsData() {
      // shopsRead({
      //   condition: {
      //     uuid: this.uuid,
      //   },
      //   full_fields: ["shop_live_log_info", "shop_live_goods_info"],
      // }).then((res) => {
      //   console.log(res);
      //   this.shopData = res.data;
      //   this.options.channel = res.data.live_log_uuid;
      //   if (res.data.shop_live_log_info.id) {
      //     this.getLiveGoodsData(res.data.live_log_uuid);
      //   }
      //   this.startBasicLive();
      // });
    },
    // getLiveGoodsData(uuid) {
    //   liveGoods({
    //     uuid,
    //   }).then((res) => {
    //     console.log(res);
    //     this.liveGoodsList = res.data;
    //   });
    // },

    async startBasicLive() {
      // let that = this;
      // let options = this.options;
      // if (!options.channel) {
      //   console.log(this.liveList);
      //   options.channel = this.liveList[0].live_log_uuid;
      // }
      // // 1.创建本地客户端
      // // rtc.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
      // // // 2.设置用户角色
      // // // Role 的值可以是 "host" 或者 "audience"。
      // // rtc.client.setClientRole(options.role, { level: 1 });
      // //3.加入目标频道
      // console.log(this.options.channel, "this.options.channel");
      // console.log(options);
      // const uid = await AgoraClient.join(
      //   options.appId,
      //   options.channel,
      //   options.token,
      //   Number(options.uid)
      // );
      // console.log(uid, "加入目标频道");
      // // 5.订阅远端用户
      // // console.log(AgoraClient.on("user-published"));
      // AgoraClient.on("user-published", async (user, mediaType) => {
      //   console.log(user, mediaType, "订阅远端用户");
      //   that.userUid = user;

      //   // 开始订阅远端用户。
      //   await AgoraClient.subscribe(user, mediaType);
      //   console.log("subscribe success");
      //   // 订阅完成后，从 `user` 中获取远端音频轨道对象。
      //   remoteAudioTrack = user.audioTrack;
      //   remoteVideoTrack = user.videoTrack;
      //   // rtc.localAudioTrack = remoteAudioTrack
      //   // rtc.localVideoTrack = remoteVideoTrack
      //   // 表示本次订阅的是音频。
      //   if (mediaType === "video") {
      //     remoteVideoTrack.play("player");
      //     that.liveBg();
      //   } else {
      //     remoteAudioTrack.play();
      //   }
      // });

      // const channel = client.createChannel(options.channel);
      // that.memberCountTime = setInterval(() => {
      //   client.getChannelMemberCount([this.options.channel]).then((res) => {
      //     that.memberCount = res[options.channel];
      //     console.log(that.memberCount);
      //   });
      // }, 1000);

      // channel.join();
      // that.channelClient = channel;
      // channel.on("ChannelMessage", function (messageObj, id) {
      //   console.log(id);
      //   let text = messageObj.text.split("  ");
      //   that.commentList.push({
      //     name: text[0],
      //     msg: text[1],
      //   });
      //   that.scrollToBottom();
      // });
    },

    liveBg() {
      var v = document.getElementsByTagName("video")[0];
      var canvas = document.getElementById("video-background");
      console.log(canvas);
      var context = canvas.getContext("2d");
      var cw = Math.floor(canvas.clientWidth);
      var ch = Math.floor(canvas.clientHeight);
      document.getElementsByTagName("video")[0].play();
      canvas.width = cw;
      canvas.height = ch;
      draw(v, context, cw, ch);
      function draw(v, c, w, h) {
        c.drawImage(v, 0, 0, w, h);
        setTimeout(draw, 20, v, c, w, h);
      }
    },

    // _videoList() {
    //     this.$http.video.hotList().then(({ code, data }) => {
    //         console.log(data);
    //         if (code === 200) {
    //             this.videoList = this.videoList.concat(data.list);
    //         }
    //     });
    // },
  },
};
</script>

<style lang="less" scoped>
.tisp {
  margin: 250px 0;
  text-align: center;
  color: green;
}

.livePlayer {
  position: relative;
  display: flex;

  .commentListW {
    background: #fff;
    height: 100%;
    position: relative;
    padding-bottom: 50px;
    box-sizing: border-box;
    z-index: 12;

    .commentList {
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
      position: relative;
      width: 333px;
      flex: 1;
      background: #fff;
      overflow-y: scroll;

      .commentitem {
        margin-bottom: 10px;
        line-height: 1px;

        span {
          background: #fff;
        }

        .commentitemName {
          font-size: 18px;
          color: #999999;
        }

        .commentitemMsg {
          font-size: 18px;
          color: #333333;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .commentBottomBox {
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      bottom: 0;
      height: 50px;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;
      position: absolute;

      .commentIptBox {
        display: flex;
        align-items: center;
        width: 209px;
        height: 36px;
        background: #f5f5f5;
        border-radius: 18px;
        font-size: 14px;
        padding: 0 12px;
        margin-right: 10px;

        .bianjiIcon {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }

        input {
          border: none;
          background: #f5f5f5;
        }
      }

      .sendMsg {
        cursor: pointer;

        .sendMsgIcon {
          display: flex;
          width: 33px;
          height: 33px;
        }
      }
    }
  }

  .livePlayerHeader {
    z-index: 10;
    position: absolute;
    top: 30px;
    width: calc(100% - 333px) !important;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .livePlayerHeaderLeft {
      display: flex;
      align-items: center;

      .shopInfo {
        display: flex;
        align-items: center;
        margin-right: 60px;

        .shopLogo {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .shopName {
          font-size: 16px;
          color: #ffffff;
        }
      }

      .liveLookNum {
        display: flex;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }

        .num {
          font-size: 16px;
          color: #ffffff;
        }
      }
    }

    .livePlayerHeaderRight {
      z-index: 11;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 88px;
      height: 38px;
      background: #ff2442;
      border-radius: 4px;
      font-size: 16px;
      color: #ffffff;
    }
  }

  .controls {
    // background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5));
    height: 0px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    z-index: 12;
    width: calc(100% - 333px) !important;
    padding: 0 30px;
    position: absolute;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .controlsLeft {
      display: flex;
      align-items: center;

      .livezanting {
        cursor: pointer;
        width: 15px;
        height: 23px;
        margin-right: 35px;
      }

      .liveshuaxin {
        cursor: pointer;
        width: 24px;
        height: 23px;
      }
    }

    .controlsRight {
      display: flex;
      align-items: center;

      img {
        cursor: pointer;
        margin-left: 20px;
      }
    }
  }

  &:hover .controls {
    height: 80px;
    opacity: 1;
  }

  .liveCartGoods {
    position: absolute;
    bottom: 100px;
    padding: 0 30px;
    z-index: 10;

    .goodsNum {
      margin-bottom: 7px;
      padding: 0 4px;
      display: inline-block;
      text-align: center;
      line-height: 26px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
      font-size: 8px;
      color: #ff2442;
    }

    .cartGoods {
      display: flex;
      align-items: center;

      img {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }

      span {
        font-size: 14px;
        color: #ffffff;
      }
    }
  }

  #player {
    width: calc(100% - 333px) !important;
    z-index: 9;
    // position: absolute;
    top: 0;
    left: 0;

    /deep/ div {
      background-color: transparent !important;
    }

    /deep/ video {
      width: auto !important;
      object-fit: contain !important;
      left: 50% !important;
      transform: translate(-50%, 0);

      &~div {
        background-color: transparent !important;
      }
    }
  }

  .video-backgroundW {
    position: absolute;
    width: calc(100% - 333px) !important;
    height: 100%;
    overflow: hidden;
  }

  #video-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    filter: blur(50px);
  }

  #playerBg {
    position: absolute;
    top: 0;
    left: 0;
    margin-right: 20px;
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 10px;

    /deep/ video {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
    }
  }

  .myCanvasW {
    width: calc(100% - 3.33rem) !important;
    position: absolute;
  }

  #myCanvas {
    position: absolute;
    left: 50% !important;
    transform: translate(-50%, 0);
    z-index: 10;
  }
}

/*video隐藏全屏按钮*/
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

/*video隐藏音量控制*/
video::-webkit-media-controls-volume-control-container {
  display: none !important;
}

.loadDowntips {
  width: 200px;
  height: 164px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;

  img {
    width: 97px;
    height: 97px;
    margin: 11px;
  }

  .text {
    font-size: 13px;
    color: #666666;
  }
}

.zhuanfaloadDowntips {
  position: relative;
  box-sizing: border-box;
  width: 305px;
  height: 346px;
  background-image: url(../../assets/zhuanfaBg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;

  .shopLogo {
    position: absolute;
    top: -34px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 68px;
    height: 68px;
    border-radius: 50%;
  }

  .searchText {
    font-size: 14px;
    color: #ffffff;
    display: flex;
    margin-bottom: 10px;

    span {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .playing {
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 32px;
  }

  .zhuanfaQr {
    display: flex;
    width: 150px;
    height: 150px;
    margin-bottom: 16px;

    img {
      width: 150px;
      height: 150px;
    }
  }

  .text {
    font-size: 14px;
    color: #ff2442;
  }
}

.progress-bar {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -2px;
  width: 96%;
  height: 10px;
  margin-top: 10px;
  background-color: #ddd;
}
.progress {
  height: 100%;
  background-color: #f56c6c;
  transition: width .1s linear;
}
</style>
<style>
.zhuanfapopover {
  background: transparent;
  border: none;
  padding: 0;
}




</style>